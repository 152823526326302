import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './authenticate/auth.guard';
import { MyHttpInterceptor, HTTPStatus } from './authenticate/token.interceptor';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './containers';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ShareButtonsModule } from '@ngx-share/buttons';
 

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomValidation } from './helper/customValidation';
import { RoundManagementService } from './views/round-management/round-management.services';
import { LoginService } from './services/login.service';
import { Validation } from './helper/validation';
import { NgxSpinnerModule } from "ngx-spinner";
import { EmojifyModule } from 'angular-emojify';
// import { Ng2CloudinaryModule } from 'ng2-cloudinary';
// import { FileUploadModule } from 'ng2-cloudinary/dist/esm/src/cloudinary-uploader.service';
// import { FileUploadModule } from 'ng2-file-upload';
// import { HeaderComponent } from './views/header/header.component';
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    EmojifyModule,
    NgxSpinnerModule,
    // Ng2CloudinaryModule,
    // FileUploadModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    // DataTablesModule,
    BrowserAnimationsModule,
    // ToastrModule.forRoot()
    ToastrModule.forRoot({
      // timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),

  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    // HeaderComponent
  ],
  providers: [
    CustomValidation,
    RoundManagementService,
    LoginService,
    Validation,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      // useClass: PathLocationStrategy
      useClass: HashLocationStrategy
    },
    HTTPStatus
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
