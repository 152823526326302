import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public submitted: boolean = false;
  private setLocalStorage = [];
  public error: any;
  
  constructor(
    public fb: FormBuilder,
    public login: LoginService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    localStorage.clear();
    this.loginForm = this.fb.group({
      'email': new FormControl('', [Validators.required, Validators.email]),
      'password': new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(16)])
    })
  }

  loginFormSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.login.login(this.loginForm.value)
        .subscribe(
          res => {
            if(res.status == 200){
              this.setLocalStorage = [{ 'email': res.user.email,'tag_id': res.user.tag_id, 'token': res.user.id,'flipkart_id': res.user.flipkart_id,'flipkart_tag': res.user.flipkart_tag}]
              localStorage.setItem('user_data', JSON.stringify(this.setLocalStorage))
              localStorage.setItem('login_token', res.user.auth_token)
              this.gettoken();
            }else{
              this.toastr.error(res.message, 'error');
            }
          }, err => {
            this.error = err.error.errors;
          }
        )
    }
  }

  gettoken() {
      this.login.singleBitlyData()
        .subscribe(
          res => {
            var item = res.data.pop();
            console.log('item: ', item);
              this.toastr.success('Login Sucessfully', 'User');
              localStorage.setItem('auth_token', item.token)
              this.router.navigate(['/dashboard']);
          }, err => {
            console.log('err: ', err);
            this.error = err.error.errors;
          }
        )
  }
}
