import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent implements OnInit{

  public loginForm: FormGroup;
  public submitted: boolean = false;
  private setLocalStorage = [];
  public error: any;
  
  constructor(
    public fb: FormBuilder,
    public login: LoginService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    localStorage.clear();
    this.loginForm = this.fb.group({
      'Username': new FormControl('', [Validators.required]),
      'email': new FormControl('', [Validators.required, Validators.email]),
      'Phonenumber': new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      'password': new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]),
      'confPassword': new FormControl('', [Validators.required])
    })
  }

  loginFormSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.login.register(this.loginForm.value)
        .subscribe(
          res => {
            if(res.status == 200){
            this.toastr.success(res.message, 'User');
            this.router.navigate(['/login'])
              // this.setLocalStorage = [{ 'email': res.user.email,'tag_id': res.user.tag_id, 'token': res.user.id}]
              // localStorage.setItem('user_data', JSON.stringify(this.setLocalStorage))
              // localStorage.setItem('login_token', res.user.auth_token)

            }else{
              this.toastr.error(res.message, 'error');
            }
          }, err => {
            console.log('err: ', err);
            this.error = err.error.errors;
          }
        )
    }
  }

}
