import { Component, OnDestroy, OnInit } from '@angular/core';
import { navItems } from './../../_nav';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit,OnDestroy {
  public navItems = navItems;
  public currentYear = new Date().getFullYear();
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public is_logged_in: Boolean = false;
  public element: HTMLElement = document.body;
  constructor(
    private router: Router,
    public login: LoginService
    ) {
      this.changes = new MutationObserver((mutations) => {
        this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
      });
      
      this.changes.observe(<Element>this.element, {
        attributes: true,
        attributeFilter: ['class']
      });
      this.is_logged_in = login.loggedIn();
    }
    
    ngOnInit(): void {
    if(this.is_logged_in){
      console.log('-----yes');
    }else{
      this.router.navigate(['/login'])
    }
  }

  Logout() {
    // let token = localStorage.getItem('auth_token');
    // this.login.doLogout(token)
    //   // .subscribe(
    //   //   queryParam => {
    //       // console.log('queryParam: ', queryParam);
    //       // if (!queryParam) {
    //       //   window.location.reload();
    //       // } else {
    //         localStorage.removeItem('user_data');
    //         localStorage.removeItem('auth_token');
    //         this.router.navigate(['/login'])
    //       // }
    //     // })
    localStorage.clear();
    this.router.navigate(['/login'])

  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
