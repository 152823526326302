import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) { }

  login(login_data): Observable<any> {
    return this.http.post<any>(`${environment.lapiUrl}login`, login_data);
  }
  register(login_data): Observable<any> {
    return this.http.post<any>(`${environment.lapiUrl}register`, login_data);
  }
  doLogout(auth_token): Observable<any> {
    return this.http.delete(`${environment.apiUrl}sessions/` + auth_token);
  }

  GetAllInOneData(data: any) {
    return this.http.post<any>(environment.apiUrl2+'api/getAllInOneData', data);
  }

  AddTotalNumberData(data: any) {
    return this.http.post<any>(environment.apiUrl2+'api/addTotalNumberData', data);
  }

  GetTotalNumberData(data: any) {
    return this.http.post<any>(environment.apiUrl2+'api/getTotalNumberData', data);
  }

  editFlipkartFlags1(data: any) {
    return this.http.post<any>(environment.apiUrl2+'editFlipkartFlags', data);
  }

  editBitlyMoz(data: any) {
    return this.http.post<any>(environment.apiUrl+'bitlyChangePostAmzn1', data);
  }

  editBitlyCrom(data: any) {
    return this.http.post<any>(environment.apiUrl2+'bitlyChangePostAmzn', data);
  }

  editFlipkartFlags2(data: any) {
    return this.http.post<any>(environment.apiUrl+'editFlipkartFlags', data);
  }
  
  AddAllInOneData(data: any) {
    return this.http.post<any>(environment.apiUrl2+'api/addAllInOneData', data);
  }

  unconvert_posts(data: any) {
    return this.http.post<any>(environment.apiUrl+'unconvert_posts_forward', data);
    // return this.http.post<any>(environment.apiUrl+'unconvert_posts', data);
  }

  unconvert_audio_posts(data: any) {
    return this.http.post<any>(environment.apiUrl+'unconvert_audio_posts', data);
  }

  unconvert_video_posts(data: any) {
    return this.http.post<any>(environment.apiUrl+'unconvert_video_posts', data);
  }

  EditAllInOneData(data: any) {
    return this.http.post<any>(environment.apiUrl2+'api/editAllInOneData', data);
  }

  EditTotalNumberData(data: any) {
    return this.http.post<any>(environment.apiUrl2+'api/editTotalNumberData', data);
  }
  
  tagChangePostFlags(data: any) {
    return this.http.post<any>(environment.apiUrl2+'tagChangePostFlags', data);
  }

  autoPhotoPostFlagss(data: any) {
    return this.http.post<any>(environment.apiUrl2+'autoPhotoPostFlags', data);
  }

  tagChangePostFlagss(data: any) {
    return this.http.post<any>(environment.apiUrl+'tagChangePostFlags', data);
  }

  autoPhotoPostFlags(data: any) {
    return this.http.post<any>(environment.apiUrl+'autoPhotoPostFlags', data);
  }
  

  EditAllBitlyServer(data: any) {
    return this.http.post<any>(environment.apiUrl2+'bitlyPostFlags', data);
  }

  editAmznTag(data: any) {
    return this.http.post<any>(environment.apiUrl2+'editAmznTag', data);
  }

  WhatsAppUpdate1(data: any) {
    return this.http.post<any>(environment.apiUrl+'WhatsAppUpdate1', data);
  }

  WhatsAppUpdate2(data: any) {
    return this.http.post<any>(environment.apiUrl2+'WhatsAppUpdate2', data);
  }

  WhatsAppUpdate3(data: any) {
    return this.http.post<any>('https://autoforwder2.herokuapp.com/WhatsAppUpdate3', data);
  }
  
  getAllPostData(data: any) {
    return this.http.post<any>(environment.apiUrl2+'getAllInOneData', data);
  } 

  editpostFlags(data: any) {
    return this.http.post<any>(environment.apiUrl2+'editpostFlags', data);
  } 

  editihdpostFlags(data: any) {
    return this.http.post<any>(environment.apiUrl2+'editihdpostFlags', data);
  } 
  
  // singlepostFlags() {
  //   return this.http.get<any>(environment.apiUrl+'singlepostFlags1');
  // }

  singlepostFlags() {
    return this.http.get<any>(environment.apiUrl2+'singlepostFlags');
  }

  postFlipkartList() {
    return this.http.get<any>(environment.apiUrl2+'listFlipkart');
  }

  groupDetails() {
    return this.http.get<any>('https://backend.top9deals.com/admin/address/grouplist' ,{ headers: new HttpHeaders({'Content-Type': 'application/json'}) });
  }

  totalMobileDetails() {
    return this.http.get<any>('https://backend.top9deals.com/admin/address/totalmobile' ,{ headers: new HttpHeaders({'Content-Type': 'application/json'}) });
  }

  mobileDetails() {
    return this.http.get<any>('https://backend.top9deals.com/admin/address/mobilelist' ,{ headers: new HttpHeaders({'Content-Type': 'application/json'}) });
  }

  whatsappDetails(data) {
    return this.http.post<any>(environment.apiUrl2+'add-member', data);
    // return this.http.post<any>('http://localhost:8000/add-member',data);
  }

  mobileDetailss(data) {
    return this.http.post<any>('https://backend.top9deals.com/admin/address/vcard_mobilelist',data);
  }

  totalWhatsappUpdate(data) {
    return this.http.post<any>('https://backend.top9deals.com/admin/address/update_totalmobile',data);
  }

  whatsappUpdate(data) {
    return this.http.post<any>('https://backend.top9deals.com/admin/address/update_mobile',data ,{ headers: new HttpHeaders({'Content-Type': 'application/json'}) });
  }

  wpClearMsg(id,token) {
    return this.http.get<any>('https://api.chat-api.com/'+id+'/clearMessagesQueue?token='+token);
  }

  wpShowMsg(id,token) {
    return this.http.get<any>('https://api.chat-api.com/'+id+'/showMessagesQueue?token='+token);
  }

  SingleAllInOneData(data: any) {
    return this.http.get<any>(environment.apiUrl+'singleAllInOneData/'+data);
  }

  SingleTotalNumberData(data: any) {
    return this.http.get<any>(environment.apiUrl+'singleTotalNumberData/'+data);
  }

  singleBitlyData() {
    return this.http.get<any>(environment.apiUrl+'singleBitlyData/');
  }

  listTagData() {
    return this.http.get<any>(environment.apiUrl+'listTagData/');
  }
  
  activepostFlags() {
    return this.http.get<any>(environment.apiUrl2+'activepostFlags');
  }

  DeleteAllInOneData(data: any) {
    return this.http.delete<any>(environment.apiUrl2+'api/deleteAllInOneData/'+data);
  }

  DeleteTotalNumberData(data: any) {
    return this.http.delete<any>(environment.apiUrl2+'api/deleteTotalNumberData/'+data);
  }

  deleteTagData(data: any) {
    return this.http.delete<any>(environment.lapiUrl+'api/deleteTagData/'+data);
  }
  

  loggedIn() {
    return !!localStorage.getItem('auth_token')
  }
  product_list(dataTablesParameters): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}warehouse/products`, { params: dataTablesParameters });
  }
  orderList(dataTablesParameters) {
    return this.http.get<any>('http://180.211.99.164:11000/v1/api/orders/admin_list', { params: dataTablesParameters });
  }

  addtokenData(data: any) {
    return this.http.post<any>(environment.apiUrl+'addtokenData', data);
  }

  addtagData(data: any) {
    return this.http.post<any>(environment.apiUrl+'addTagData', data);
  }
  automation_posts(data: any) {
    return this.http.post<any>(environment.apiUrl+'automation_posts', data);
  }

  //bit.ly api
  Getgroups() {
    return this.http.get<any>('https://api-ssl.bitly.com/v4/groups',{ headers: new HttpHeaders({'Authorization': 'Bearer ' + localStorage.getItem('auth_token')}) });
  }

  User() {
    return this.http.get<any>('https://api-ssl.bitly.com/v4/user',{ headers: new HttpHeaders({'Authorization': 'Bearer ' + localStorage.getItem('auth_token')}) });
  }

  referring_networks(data) {
    return this.http.get<any>('https://api-ssl.bitly.com/v4/groups/'+data+'/referring_networks',{ headers: new HttpHeaders({'Authorization': 'Bearer ' + localStorage.getItem('auth_token')}) });
  }

  shorten_counts(data) {
    return this.http.get<any>('https://api-ssl.bitly.com/v4/groups/'+data+'/shorten_counts',{ headers: new HttpHeaders({'Authorization': 'Bearer ' + localStorage.getItem('auth_token')}) });
  }

  countriesData(data) {
    return this.http.get<any>('https://api-ssl.bitly.com/v4/groups/'+data+'/countries',{ headers: new HttpHeaders({'Authorization': 'Bearer ' + localStorage.getItem('auth_token')}) });
  }

  bitlinksClickData(data) {
    return this.http.get<any>('https://api-ssl.bitly.com/v4/groups/'+data+'/bitlinks/clicks',{ headers: new HttpHeaders({'Authorization': 'Bearer ' + localStorage.getItem('auth_token')}) });
  }

  shorten(data) {
    return this.http.post<any>('https://api-ssl.bitly.com/v4/shorten',data,{ headers: new HttpHeaders({'Authorization': 'Bearer ' + localStorage.getItem('auth_token')}) });
  }
  
}
