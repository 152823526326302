// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false,
//   lapiUrl: "http://localhost:3000/",
//   apiUrl: "http://localhost:3000/api/",
//   apiUrl2: "https://postmanualdemo1.herokuapp.com/",
//   // apiUrl: 'http://hairfolio-prod.herokuapp.com/',
//   defaultImageUrl: 'http://localhost/sweet_science/assets/uploads/meal_product_placeholders.png',
// };
export const environment = {
  production: false,
  // lapiUrl: "https://panelangular.herokuapp.com/",
  // apiUrl: "https://panelangular.herokuapp.com/api/",
  // apiUrl2: "http://localhost:3000/",
  // lapiUrl: "https://nodepanel3.herokuapp.com/",
  // apiUrl: "https://nodepanel3.herokuapp.com/api/",
  // apiUrl2: "https://postmanual2.herokuapp.com/",

  // lapiUrl: "https://autotest8.onrender.com/",
  // apiUrl: "https://autotest8.onrender.com/api/",
  // apiUrl2: "https://dummydemo-xs3r.onrender.com/",

  // lapiUrl: "https://dummydemo-xs3r.onrender.com/",
  // apiUrl: "https://dummydemo-xs3r.onrender.com/api/",
  // apiUrl2: "https://autotest8.onrender.com/",

  lapiUrl: "https://dummydemo-mdu6.onrender.com/",
  apiUrl: "https://dummydemo-mdu6.onrender.com/api/",
  // apiUrl: "http://localhost:7001/api/",
  apiUrl2: "https://dummydemo-mdu6.onrender.com/",

  // lapiUrl: "https://nodepanel8.herokuapp.com/",
  // apiUrl: "https://nodepanel8.herokuapp.com/api/",
  // apiUrl2: "https://postmanual7.herokuapp.com/",

 
  // apiUrl: 'http://hairfolio-prod.herokuapp.com/',
  defaultImageUrl: 'http://localhost/sweet_science/assets/uploads/meal_product_placeholders.png',


  // lapiUrl: "https://nodepanel4.herokuapp.com/",
  // apiUrl: "https://nodepanel4.herokuapp.com/api/",
  // apiUrl2: "https://postmanual3.herokuapp.com/",
  // // apiUrl: 'http://hairfolio-prod.herokuapp.com/',
  // defaultImageUrl: 'http://localhost/sweet_science/assets/uploads/meal_product_placeholders.png',
};