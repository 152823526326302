import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class RoundManagementService {
  constructor(private http: HttpClient) { }

  
  
}
