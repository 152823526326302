import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Meta } from '@angular/platform-browser';
import { HTTPStatus } from './authenticate/token.interceptor';
@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple"></ngx-spinner><router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private meta: Meta,private router: Router, private spinner: NgxSpinnerService, private httpStatus: HTTPStatus) {
    // this.meta.addTag({ name: 'description', content: 'How to use Angular 4 meta service' });
    this.httpStatus.getHttpStatus()
      .subscribe((status: boolean) => {
        if (status) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
      });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
