import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpHeaders
} from '@angular/common/http';
import { map, catchError, finalize } from 'rxjs/operators';
import 'rxjs/add/observable/throw'
import { Router } from '@angular/router';

@Injectable()
export class HTTPStatus {
    private requestInFlight$: BehaviorSubject<boolean>;
    constructor(
        public router: Router) {
        this.requestInFlight$ = new BehaviorSubject(false);
    }

    setHttpStatus(inFlight: boolean) {
        this.requestInFlight$.next(inFlight);
    }

    getHttpStatus(): Observable<boolean> {
        return this.requestInFlight$.asObservable();
    }
}

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    constructor( private route: Router,private status: HTTPStatus,
        ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        const token = localStorage.getItem('login_token');
        const tokens = localStorage.getItem('auth_token');
        if (token && tokens && !req.url.includes('backend.top9deals.com/admin/address') && !req.url.includes('wpallserver.herokuapp.com')) {
            console.log('111');
            req = req.clone({
                headers: new HttpHeaders({
                    //'Content-Type': 'application/json',
                    'authorizations': token,
                    'authorization': tokens
                })
            });
        }else if (token && !req.url.includes('backend.top9deals.com/admin/address') && !req.url.includes('wpallserver.herokuapp.com')) {
            console.log('222');
            req = req.clone({
                headers: new HttpHeaders({
                    //'Content-Type': 'application/json',
                    'authorizations': token
                })
            });
        } else {
            req = req.clone();
        }
        
        return next.handle(req).pipe(
            map(event => {
                this.status.setHttpStatus(true);
                return event;
                
            }),
            catchError(error => {
                if (error.status === 401) {
                    localStorage.removeItem('user_data');
                    localStorage.removeItem('auth_token');
                    localStorage.removeItem('login_token');
                    this.route.navigate(['/login']);
                }
                return Observable.throw(error);
            }),
            finalize(() => {
                this.status.setHttpStatus(false);
            })
        )
    }
}
